import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import userData from "./hooks/useData";
import { Dynamicards } from "./Components/DynamicCard";
import { API_URL } from "./services";
import { type programSchema } from "./interfaces/dashboard.interface";
import SearchFilter from "./filters/SearchFilter";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";

export default function Dashboard() {
  const { user } = userData();
  const [programsList, setProgramsList] = useState<programSchema[]>([]);
  const [filteredProgramList, setFilteredProgramList] = useState<
    programSchema[]
  >([]);

  const fetchProgram = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/learnerprograms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (!response.ok) {
        toast.error("Error in fetching Learner's data");
        return;
      }

      const result = await response.json();
      setProgramsList(result.programs);

      return result.programs;
    } catch (error) {
      toast.error(error);
      setProgramsList([]);
    }
  }, []);

  useEffect(() => {
    const fetchProgramData = async () => {
      await fetchProgram();
    };

    void fetchProgramData();
  }, [fetchProgram]);

  const countStatus = useMemo(() => {
    let status = {
      yet_to_start: 0,
      in_progress: 0,
      completed: 0,
      expired: 0,
    };

    programsList.forEach((program) => {
      if (new Date() > new Date(program.program_end_date)) {
        status.expired += 1;
      } else {
        if (program.program_progress === 0) {
          status.yet_to_start += 1;
        } else if (program.program_progress === 100) {
          status.completed += 1;
        } else {
          status.in_progress += 1;
        }
      }
    });

    return status;
  }, [programsList]);

  const telegramProfile = useMemo(() => {
    return user?.social_profiles?.find(
      (profile: any) => profile.name === "telegram"
    );
  }, [user?.social_profiles]);

  return (
    <div className="main-head-strap w-auto h-auto">
      <div className="p-8 flex flex-col gap-10">
        <h1 className="text-4xl antialiased font-medium">
          Welcome {user?.learner_name}!
        </h1>
        <div className="flex flex-col gap-10">
          <div className="layer-3 flex gap-10">
            <div className="box-layer-1 flex flex-col justify-center gap-4 text-left pl-3 w-40 h-40 bg-yet-to-start rounded text-white">
              <h1 className="text-3xl antialiased  font-bold text-left">
                {countStatus.yet_to_start}
              </h1>
              <h3 className="text-xl antialiased font-normal  text-left">
                Yet to Start
              </h3>
            </div>
            <div className="box-layer-1 flex flex-col justify-center gap-4 text-left pl-3 w-40 h-40 bg-in-progress rounded text-white">
              <h1 className="text-3xl antialiased font-bold text-left">
                {countStatus.in_progress}
              </h1>
              <h3 className="text-xl antialiased font-normal text-left">
                In Progress
              </h3>
            </div>
            <div className="box-layer-1 flex flex-col justify-center gap-4 text-left pl-3 w-40 h-40 bg-expired rounded text-white">
              <h1 className="text-3xl antialiased font-bold text-left">
                {countStatus.expired}
              </h1>
              <h3 className="text-xl antialiased font-normal text-left">
                Expired
              </h3>
            </div>
            <div className="box-layer-1 flex flex-col justify-center gap-4 text-left pl-3 w-40 h-40 bg-completed rounded text-white">
              <h1 className="text-3xl antialiased font-bold text-left">
                {countStatus.completed}
              </h1>
              <h3 className="text-xl antialiased font-normal  text-left">
                Completed
              </h3>
            </div>
            {!telegramProfile?.chat_id && (
              <div className="flex flex-row justify-center box-layer-1 gap-4 text-left pl-3 w-100 h-40 border-2 rounded ">
                <QRCode
                  size={256}
                  style={{ height: "100%", maxWidth: "100%", width: "auto" }}
                  value="https://t.me/sfl_alert_check_bot" // move this value to env or something in production
                  viewBox={`0 0 256 256`}
                />
              <div className="flex flex-col  ">
                <p>
                  To access the Telegram bot:<br></br>
                  1. Add your Telegram username to your profile.<br></br>
                  2. Click <a href="https://t.me/notifyassessmentbot" target="_blank" rel="noreferrer" className="text-blue-700">here</a> and send
                  a 'Hi' message to the bot.
                </p>
              </div>
              </div>
            )}
          </div>
          <SearchFilter
            programList={programsList}
            setFilteredProgramList={setFilteredProgramList}
          />
        </div>

        {filteredProgramList.length !== 0 &&
          filteredProgramList.map((value, index) => {
            return <Dynamicards data={value} key={index}></Dynamicards>;
          })}

        {/* Empty List Case */}
        {programsList.length === 0 && (
          <div className="bg-white border-2 flex flex-col gap-4 p-8 rounded-md shadow-md items-center">
            <div>No Programs Assigned</div>
          </div>
        )}
        {programsList.length !== 0 && filteredProgramList.length === 0 && (
          <div className="bg-white border-2 flex flex-col gap-4 p-8 rounded-md shadow-md items-center">
            <div>No Program matches Search Criteria</div>
          </div>
        )}
      </div>
    </div>
  );
}
