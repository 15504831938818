import React from "react";
import { FaDownload } from "react-icons/fa";
import { ProgressBar } from "./ProgressBar";
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import { HiInformationCircle } from "react-icons/hi";
import axios from "axios";
import { API_URL } from "../services/index";

const Attempt = ({ data, deadline, activity }) => {
  const activity_result_data = activity.result_published
  const {report, score_display}=activity
  const { "Participant status": participantStatus, "attempt_number": attemptNumber, "Code Playback Link": playbackLink, "AMCAT ID": amcatId, score } = data;
  const getTooltipMessage = () => {
    switch (score_display) {
      case "instant":
        return report === "instant" ? "Please wait while your assessment or scoring is being completed." : "Scores are subject to availability based on the chosen settings.";
      case "after_deadline":
        return report === "after_deadline" ? "Your reports will only be available after the deadline for this activity has passed." : "Scores are subject to availability based on the chosen settings.";
      case "manual":
        return "Your reports will be accessible once they are released by the admin.";
      default:
        return "Your  reports will be accessible once they are released by the admin.";
    }
  };

  const handleDownload = async () => {
    try {
      const response = await axios.post(`${API_URL}/getReportFile`, { 'AMCAT ID': amcatId });
      const reportUrl = response.data.reportUrl;
      window.open(reportUrl, "_blank");
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };
  console.log(data['score'])
  return (
    <div className="my-3">
      <div className="w-fit flex justify-start bg-[#D9D9D9] p-2 rounded-md mb-1">
        <span className="mr-2">Status: </span>
        <span>{participantStatus || data["score"][0]}</span>
      </div>
      <div className="bg-[#D9D9D9] flex flex-col gap-2 rounded-md">
        <div className="flex justify-between">
          <div className="flex flex-col items-center p-4">
            <p>Attempt No.</p>
            <p className="text-3xl">{attemptNumber}</p>
          </div>
          {playbackLink && (
            <div className="flex flex-row items-center p-4">
              <p>View Report</p>
              <HiMiniViewfinderCircle
                onClick={() => window.open(playbackLink, "_blank")}
                className="w-12 cursor-pointer"
                aria-label="View Report"
              />
            </div>
          )}
          <button 
            className="flex flex-row items-center p-4"
            disabled={report!=='instant' || (report === 'after_deadline' && new Date(deadline) < new Date()) || (report==='manual' && activity_result_data )} //TO DO Change true to manual publish status
          >
            <p>Download Report</p>
            <FaDownload
              onClick={handleDownload}
              className="w-12 cursor-pointer"
            />
            <HiInformationCircle
              className="w-6 ml-2 cursor-pointer"
              title={getTooltipMessage()}
              aria-label="Information about score and report"
            />
          </button>
        </div>
        <div className="flex flex-col">
          {score ? Object.keys(score).map((keyName) => {
            const scoreValue = score[keyName];
            const isDeadlinePassed = new Date(deadline) < new Date();

            return (
              <div className="p-4 rounded" key={keyName}>
                {score_display === 'instant' && scoreValue ? (
                  <>
                    <p>{keyName}</p>
                    <ProgressBar status={scoreValue} />
                  </>
                ) : score_display === 'instant' ? (
                  <p>Please wait while your assessment or scoring is being completed.</p>
                ) : score_display === 'after_deadline' && isDeadlinePassed ? (
                  <>
                    <p>{keyName}</p>
                    <ProgressBar status={scoreValue} />
                  </>
                ) : score_display === 'after_deadline' ? (
                  <p>Your score will only be available after the deadline {deadline} for this activity has passed.</p>
                ) : score_display === 'manual' && scoreValue ? (
                  <>
                    <p>{keyName}</p>
                    <ProgressBar status={scoreValue} />
                  </>
                ) : (
                  <p>Your score will be accessible once they are released by the admin.</p>
                )}
              </div>
            );
          }) : (
            <p>-</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Attempt;
